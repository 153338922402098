(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:ImportPlayers
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('ImportPlayers', ImportPlayers);

  function ImportPlayers($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/teams/:teamId/importPlayers', {teamId: '@_teamId'});
  }
}());
